import { FeatureSection, FeatureList, Feature, FeatureImage, FeatureTitle, FeatureText } from 'smooth-doc/components';
import logo from "../../../../images/logo-manifest.png";
import AltMedApp from "../../../../images/alt-med-app.png";
import Prakti from "../../../../images/benutzen.png";
import componentsImageURL from "../../../../images/components.png";
import * as React from 'react';
export default {
  FeatureSection,
  FeatureList,
  Feature,
  FeatureImage,
  FeatureTitle,
  FeatureText,
  logo,
  AltMedApp,
  Prakti,
  componentsImageURL,
  React
};